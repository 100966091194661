body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Added CSS */
.bg-lightyellow {
  background: lightyellow !important;
}

/* Highlight Disabled - begin */
input:disabled, textarea:disabled, select:disabled {
  color: black;
}

input[type=checkbox]:disabled:checked{
  background: black; 
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 12px;
  width: 12px;
}
/* Highlight Disabled - end */

.form-group {
  margin-bottom: 0.1rem !important;
}

label {
  margin-bottom: 0.1rem !important;
}

/* Tabs - begin */
.nav-link {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: hand;
  cursor: pointer;
}

.nav-tabs .nav-link:hover {
  background-color: whitesmoke;
}

.nav-tabs .nav-link.active:hover {
  background-color: whitesmoke;
}
/* Tabs - end */

/* Modal - begin */
.modal {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  display: none;
}

.modal-body {
  padding-top: 0.3rem !important;
  padding-bottom: 0.5rem !important;
}
/* Modal - end */

/* valid / invalid background-image: none - begin */
.form-control.is-valid, .was-validated .form-control:valid {
    background-image: none !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
}

#approved, #hepNSFill, #coCompleted {
  padding-right: 0.2rem!important;
}
/* valid / invalid background-image: none - end */

/* media - begin */
#contentView {
  height: calc(100vh - 120px) !important;
}

/* Medium - width ≥ 768px < 992px*/
@media (max-width: 991px) {
  #contentView {
    /* height: calc(100vh - 113px); */
    height: calc(100vh - 45px) !important;
  }
  .table-overflow {
    /* height: calc(100vh - 300px); */
    height: calc(100vh - 155px) !important;
  }
  .navbar {
    /* padding: 0.5rem 1rem; */
    padding: 0.30rem 0.7rem !important;
  }
  span.navbar-brand > img {
    /* height: 35px; */
    height: 25px !important;
  }
  .navbar-toggler {
    /* font-size: 1.25rem;
    line-height: 1; */
    font-size: 1rem !important;
    line-height: 0.8 !important;
  }
  .card-body {
    /* padding: 1.25rem; */
    padding: 0.25rem 1rem !important;
  }
  #contentViewName {
    font-size: 0.75rem !important;
    color: lightyellow !important;
  }
  #navbarApplicationName {
    /* font-size: 0.85rem !important; */
    font-size: 0.80rem !important;
    color: lightskyblue !important;
  }
  .separator {
    /* font-size: 0.9rem !important; */
    font-size: 0.7rem !important;
  }
}

/* Small - width ≥ 576px < 768px*/
@media (max-width: 767px) {
  .table-overflow {
    /* height: calc(100vh - 300px); */
    height: calc(100vh - 150px) !important;
  }
  .navbar {
    /* padding: 0.5rem 1rem; */
    /* padding: 0.25rem 0.5rem !important; */
    padding: 0.20rem 0.4rem !important;
  }
  span.navbar-brand > img {
    /* height: 35px; */
    height: 20px !important;
  }
  .navbar-toggler {
    /* font-size: 1.25rem;
    line-height: 1; */
    font-size: 0.75rem !important;
    line-height: 0.6 !important;
  }
}

/* Extra small - width < 576px*/
@media (max-width: 575px) {
  .table-overflow {
    /* height: calc(100vh - 300px); */
    height: calc(100vh - 255px) !important;
  }
  #contentNavbarApplicationName {
    font-size: 0.9rem !important;
  }
}
/* media - end */